import React, { useEffect, useState } from "react";

import { getFormSubmissions, supabase } from "../../helpers/supabase";
import { inHouseForms } from "../../config/inHouseForms";
import {
  getFieldValueToExpose,
  getTagBgColor,
  getTagFontColor,
  isOnAdminPage,
  logDev,
  capitalizeFirstChar
} from "../../helpers/globalHelpers";
import { CgFileDocument } from "react-icons/cg";
import { MdOutlineDoneAll } from "react-icons/md";
import { useAuth } from "../../context/auth";

import { RingLoader } from "react-spinners";
import { navigate } from "gatsby";

import { disabledStatuses } from "../InHouseForm/InHouseForm.config";

import Table, { filterDateString } from "../Table";

const SubmissionTable = ({ formType }) => {
  const { user, dba, admin } = useAuth();

  const columns = React.useMemo(
    () => [
      {
        Header: "Submitter",
        accessor: "submitter",
      },
      {
        Header: "Form",
        accessor: "name",
        Cell: ({ cell: { value } }) => {
          const [fieldValueToExpose, formName, fieldNameToExpose] =
            value.split("|");
          return (
            <div>
              {fieldNameToExpose !== "undefined" &&
              fieldValueToExpose !== "" ? (
                <>
                  <p>{fieldValueToExpose}</p>
                  <p class="is-size-7"> {formName} </p>
                </>
              ) : (
                <p>{formName}</p>
              )}
            </div>
          );
        },
        sortType: (rowA, rowB, id) => {
          const valA = rowA.values[id].toLowerCase();
          const valB = rowB.values[id].toLowerCase();
          if (valA > valB) {
            return 1;
          } else if (valA < valB) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: "Updated",
        accessor: "lastUpdatedTime",
        Cell: ({ cell: { value } }) => {
          return (
            <div>
              <p>{value.toLocaleDateString()}</p>
              <p class="is-size-7">{value.toLocaleTimeString()}</p>
            </div>
          );
        },
        sortType: "datetime",
        filter: filterDateString,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            class="tag"
            style={{
              backgroundColor: getTagBgColor(value),
              color: getTagFontColor(value),
              minWidth: "80px",
            }}
          >
            {capitalizeFirstChar(value)}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ({ cell: { value } }) => {
          const [formType, formId, submissionId, status] = value.split("|");
          return (
            <button
              class="button is-small"
              onClick={() => {
                navigate(
                  `/editForm?formType=${formType}&formId=${formId}&submissionId=${submissionId}&prevPage=${window.location.pathname}`
                );
              }}
            >
              {disabledStatuses.includes(status) && !admin ? "View" : "Edit"}
            </button>
          );
        },
        Filter: <div />,
      },
    ],
    []
  );

  const [formSubmissions, setFormSubmissions] = useState();
  const [loading, setLoading] = useState(true);

  const filterSubmissions = (submissions) => {
    // For filtering submissions when logged in as admin but on user view
    // Use Database version of permissions to remain consistent with RLS
    const filterSubmission = (submission) => {
      if (submission.permission_level === "email") {
        return submission.email === user.email;
      } else if (submission.permission_level === "dba") {
        return submission.dba === dba;
      }
    };

    return submissions.filter((submission) => filterSubmission(submission));
  };

  const prepareSubmissionsForTable = (submissions) => {
    // Filter submissions if on user page, logged in as admin user
    const filteredSubmissions = isOnAdminPage()
      ? submissions
      : filterSubmissions(submissions);

    const preppedSubmissions = filteredSubmissions.map((submission) => {
      const latestConfig = inHouseForms[submission.form_id];
      const response = JSON.parse(submission.response);

      return {
        // IMPORTANT: pass values as strings so they're searchable!!
        submissionId: submission.submission_id,
        submitter: submission.email,
        name: `${getFieldValueToExpose({
          response: response,
          config: latestConfig,
        })}|${latestConfig.formName}|${latestConfig.fieldNameToExpose}`,
        lastUpdatedTime: new Date(submission.last_updated_time),
        // lastUpdatedTime: lastUpdatedTimeLocal,
        status: submission.status,
        edit: `${submission.form_type}|${submission.form_id}|${submission.submission_id}|${submission.status}`,
        permissionLevel: submission.permission_level,
        formId: submission.form_id,
      };
    });

    return preppedSubmissions;
  };

  const deleteFunc = (submissionId) => {
    return supabase
      .from("fact_form_submissions")
      .delete()
      .eq("submission_id", submissionId);
  };

  useEffect(() => {
    if (typeof formSubmissions === "undefined") {
      logDev(`fetching form submissions for formType: ${formType}`);
      getFormSubmissions(formType).then((response) => {
        setFormSubmissions(prepareSubmissionsForTable(response));
        setLoading(false);
      });
    }
  });

  useEffect(() => {
    // This forces a table reload when navigate between
    // selectForm?formType=complianceForms and
    // selectForm?formType=customerInformationForms
    setFormSubmissions(undefined);
    setLoading(true);
  }, [formType]);

  const data = React.useMemo(() => formSubmissions, [formSubmissions]);

  if (loading) {
    return (
      <div class="has-text-centered fpNoSubmissionsIcon">
        <RingLoader />
      </div>
    );
  } else if (formSubmissions.length === 0) {
    return (
      <div class="has-text-centered">
        {formType === "status:openSubmissions" ? (
          <>
            <MdOutlineDoneAll size={70} class="fpNoSubmissionsIcon" />
            <p style={{ paddingBottom: ".5rem" }}>
              No open submissions at this time.
            </p>
            <p class="is-size-7">
              Forms with a status{" "}
              <span
                style={{
                  backgroundColor: getTagBgColor("submitted"),
                  color: getTagFontColor("submitted"),
                }}
                class="tag"
              >
                submitted
              </span>
              ,{" "}
              <span
                style={{
                  backgroundColor: getTagBgColor("changes requested"),
                  color: getTagFontColor("changes requested"),
                }}
                class="tag"
              >
                changes requested
              </span>
              ,{" "}
              <span
                style={{
                  backgroundColor: getTagBgColor("modified"),
                  color: getTagFontColor("modified"),
                }}
                class="tag"
              >
                modified
              </span>{" "}
              , or <span class="tag">in progress</span> will show up here
            </p>
          </>
        ) : (
          <>
            <CgFileDocument size={70} class="fpNoSubmissionsIcon" />
            <p>There are no submitted forms</p>
            {isOnAdminPage() ? (
              <p />
            ) : (
              <p class="is-size-7">Choose a form to get started</p>
            )}
          </>
        )}
      </div>
    );
  } else {
    let tableName;
    if (formType === "complianceForms") {
      tableName = "Compliance Forms";
    } else if (formType === "customerInformationForms") {
      tableName = "Customer Information Forms";
    } else if (formType === "status:openSubmissions") {
      tableName = "Open Submissions";
    } else {
      tableName = "";
    }
    return (
      <Table
        tableName={tableName}
        deleteId="submissionId"
        deleteFunc={deleteFunc}
        columns={columns}
        data={data}
        sortBy={[{ id: "lastUpdatedTime", desc: true }]}
        searchText="Search submitted forms by ID, name, status, etc."
      />
    );
  }
};

export default SubmissionTable;
