export const statusOptions = [
  "submitted",
  "modified",
  "changes requested",
  "approved",
  "rejected",
  "closed",
  "terminated",
  "trails",
  "hold",
];

export const statusesDisabledForReps = ["approved", "rejected"];

export const disabledStatuses = [
  "approved",
  "rejected",
  "closed",
  "terminated",
  "trails",
  "hold",
];
