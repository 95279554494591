import React from "react";

import "../styles/styles.scss";

import { AuthProvider } from "../context/auth";
import { AdminProvider } from "../context/adminContext";
import { useAuth } from "../context/auth";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import SubmissionTable from "../components/SubmissionTable";
import AdminUserManagement from "../components/adminUserManagement";
import UserDataTable from "../components/DataTables/UserDataTable";
import EngagementsTable2 from "../components/DataTables/EngagementsTable2";
import InvestorsTable from "../components/DataTables/InvestorsTable";
import PstTable from "../components/DataTables/PstTable";
import ObaTable from "../components/DataTables/ObaTable";
import OutsideBrokerageAccountTable from "../components/DataTables/OutsideBrokerageAccountTable";
import PoliticalContributionsTable from "../components/DataTables/PoliticalContributionsTable";
import CyberSecurityTable from "../components/DataTables/CyberSecurityTable";

const Admin = () => {
  const { user, dba, admin } = useAuth();
  const userEmail = user.email;
  const userInfo = {
    email: userEmail,
    dba,
    admin,
  };

  const [section, setSection] = React.useState("complianceForms");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setSection(urlParams.get("section"));
  });

  const switcher = (section) => {
    // Swaps out the section based on URL parameters
    switch (section) {
      case "complianceForms":
        return (
          <div id="complianceForms">
            <p class="title">Compliance Forms</p>
            <SubmissionTable formType="complianceForms" />
          </div>
        );

      case "customerInformationForms":
        return (
          <div id="customerInformationForms">
            <p class="title">Customer Information Forms</p>
            <SubmissionTable formType="customerInformationForms" />
          </div>
        );

      case "userData":
        return (
          <div id="userData">
            <p class="title">Reps</p>
            <UserDataTable userInfo={userInfo} />
          </div>
        );

      case "engagements2":
        return (
          <div id="engagements2">
            <p class="title">Engagements (Admin View)</p>
            <EngagementsTable2 userInfo={userInfo} />
          </div>
        );

      case "investors":
        return (
          <div id="investors">
            <p class="title">Investors</p>
            <InvestorsTable userInfo={userInfo} />
          </div>
        );

      case "pst":
        return (
          <div id="pst">
            <p class="title">Private Security Transactions</p>
            <PstTable userInfo={userInfo} />
          </div>
        );

      case "oba":
        return (
          <div id="oba">
            <p class="title">Outside Business Activity</p>
            <ObaTable userInfo={userInfo} />
          </div>
        );

      case "outside-brokerage-account":
        return (
          <div id="outside-brokerage-account">
            <p class="title">Outside Brokerage Accounts (Admin View)</p>
            <OutsideBrokerageAccountTable userInfo={userInfo} />
          </div>
        );

      case "political-contributions":
        return (
          <div id="political-contributions">
            <p class="title">Political Contributions & Gifts</p>
            <PoliticalContributionsTable userInfo={userInfo} />
          </div>
        );

      case "cyber-security":
        return (
          <div id="cyber-security">
            <p class="title">Cyber Security</p>
            <CyberSecurityTable userInfo={userInfo} />
          </div>
        );

      case "userManagement":
        return (
          <div id="userManagement">
            <p class="title">Access Management</p>
            <AdminUserManagement />
          </div>
        );

      default:
        return (
          <div id="complianceForms">
            <p class="title">Compliance Forms</p>
            <SubmissionTable formType="complianceForms" />
          </div>
        );
    }
  };

  if (!admin) {
    navigate("/");
    return <div />;
  } else {
    return <Layout pageName="Admin">{switcher(section)}</Layout>;
  }
};

const AdminPage = () => (
  <AuthProvider>
    <AdminProvider>
      <Admin />
    </AdminProvider>
  </AuthProvider>
);

export default AdminPage;
