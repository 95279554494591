import React from "react";

// External
import { RingLoader } from "react-spinners";

// Internal
import { supabase } from "../../helpers/supabase";
import TableV2 from "../TableV2/TableV2";
import { isOnAdminPage } from "../../helpers/globalHelpers";
import { fetchViews } from "../TableV2/helpers";

const UserDataTable = ({ userInfo }) => {
  const userEmail = userInfo.email;

  const TABLE_ID = "users";
  // State
  const [loading, setLoading] = React.useState(true);
  const [userAttributes, setUserAttributes] = React.useState([]);
  const [doRefresh, setDoRefresh] = React.useState(true);

  // Input data
  const getUserData = () => {
    return supabase.rpc("user_attributes_with_calcs").select("*");
  };

  // Add row to table function
  const addUser = () => {
    return supabase.from("dim_user_attributes").insert({});
  };

  // Delete row from table function
  const deleteUser = (userId) => {
    // console.log(`Delete user: ${userId}`);
    return supabase.from("dim_user_attributes").delete().eq("user_id", userId);
  };

  // Update user when edit
  const updateUser = (data) => {
    // console.log("Update user data: ", data);
    return supabase
      .from("dim_user_attributes")
      .upsert(data, { onConflict: "user_id" });
  };

  // Fetch user data from the database
  // Pass in setDoRefresh into the table
  // to control whether the table does a refresh against the data
  React.useEffect(() => {
    if (doRefresh) {
      getUserData().then((response) => {
        setUserAttributes(response.data);
        setLoading(false);
        setDoRefresh(false);
      });
    }
  }, [doRefresh]);

  const [views, setViews] = React.useState({});
  const [viewsLoading, setViewsLoading] = React.useState(true);
  const [doViewRefresh, setDoViewRefresh] = React.useState(true);
  React.useEffect(() => {
    fetchViews(
      userEmail,
      TABLE_ID,
      setViews,
      setViewsLoading,
      setDoViewRefresh
    );
  }, [doViewRefresh]);

  // Table configuration
  const columns = React.useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "user_id",
        idColumn: true,
        isCalculated: true,
      },
      { Header: "First Name", accessor: "first_name" },
      { Header: "Last Name", accessor: "last_name" },
      { Header: "DBA Entity", accessor: "dba" },
      { Header: "Work Email", accessor: "email" },
      { Header: "CRD #", accessor: "crd_number" },
      {
        Header: "Initial Filing Date",
        accessor: "filing_date",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      {
        Header: "Status",
        accessor: "status",
        columnType: "enum",
        options: {
          values: ["Prospect", "Pending", "Active", "U5"],
          className: "tag",
          bgColorMap: {
            Prospect: "hsl(55, 80%, 85%)",
            Pending: "hsl(207, 61%, 80%)",
            Active: "hsl(153, 53%, 53%)",
            U5: "hsl(0, 0%, 86%)",
          },
          fontColorMap: {
            Prospect: "hsl(55, 65%, 20%)",
            Pending: "hsl(207, 61%, 15%)",
            Active: "hsl(153, 53%, 97%)",
            U5: "hsl(0, 0%, 20%)",
          },
        },
      },
      {
        Header: "Reg BI Status",
        accessor: "reg_bi_crs",
        columnType: "enum",
        options: { values: ["Institutional", "Retail", "Not specified"] },
      },
      {
        Header: "ICA Executed",
        accessor: "onboard_ica",
        columnType: "boolean",
      },
      {
        Header: "Fingerprints",
        accessor: "oboard_prints",
        columnType: "boolean",
      },
      {
        Header: "Background Check",
        accessor: "onboard_bg_check",
        columnType: "boolean",
      },
      {
        Header: "IRS W-9 Form",
        accessor: "onboard_w9",
        columnType: "boolean",
      },
      {
        Header: "AML Information",
        accessor: "onboard_aml",
        columnType: "boolean",
      },
      {
        Header: "Email Monitoring",
        accessor: "onboard_email_monitor",
        columnType: "boolean",
      },
      {
        Header: "Social Media Monitoring",
        accessor: "onboard_social_monitor",
        columnType: "boolean",
      },
      {
        Header: "Signature Approved",
        accessor: "onboard_signature_on_file",
        columnType: "boolean",
      },
      {
        Header: "Business Card On File",
        accessor: "onboard_business_card_on_file",
        columnType: "boolean",
      },
      {
        Header: "Website Approved",
        accessor: "onboard_website",
        columnType: "boolean",
      },
      { Header: "Personal Email", accessor: "onboard_personal_email" },
      {
        Header: "Prospective Start Date",
        accessor: "prospect_date",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      { Header: "Referral Individual", accessor: "referral" },
      { Header: "Office Phone", accessor: "office_phone" },
      { Header: "Cell Phone", accessor: "cell_phone" },
      { Header: "Office Address", accessor: "office_address_1" },
      { Header: "Office Address 2", accessor: "office_address_2" },
      { Header: "City", accessor: "office_city" },
      { Header: "State", accessor: "office_state" },
      { Header: "Zip Code", accessor: "office_zip" },
      {
        Header: "Private Residence",
        accessor: "private_residence",
        columnType: "boolean",
      },
      {
        Header: "Office Type",
        accessor: "office_location",
        columnType: "enum",
        options: {
          values: [
            "NSBO",
            "NON",
            "OSJ",
            "PR",
            "BR",
            "OO",
            "OC",
            "Not specified",
          ],
        },
      },
      {
        Header: "Registration Licenses",
        accessor: "license_series",
        columnType: "list-multi-select", // - do we want freeform?
        options: {
          values: [
            "3",
            "6",
            "7",
            "14",
            "22",
            "24",
            "27",
            "28",
            "30",
            "79",
            "82",
            "99",
            "Not specified",
          ],
        },
      },
      {
        Header: "Registration Approval Date",
        accessor: "fs_approval_date",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      {
        Header: "Industry Start Date",
        accessor: "industry_start_date",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      {
        Header: "U5 Date",
        accessor: "u5_date",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      { Header: "Branch CRD #", accessor: "branch_crd" },
      {
        Header: "Branch role",
        accessor: "branch_role",
        columnType: "enum",
        options: {
          values: ["Supervising Principal", "Person in Charge", "N/A"],
        },
      },
      {
        Header: "Inspection Cycle",
        accessor: "inspection_cycle",
        columnType: "enum",
        options: { values: ["Annual", "3 Year", "Not specified"] },
      },
      {
        Header: "Last Inspection",
        accessor: "last_inspection",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      {
        Header: "Next Inspection",
        accessor: "next_inspection",
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      {
        Header: "PDF Pre-Registration Form",
        accessor: "pdf_prs_flag",
        columnType: "boolean",
      },
      {
        Header: "PDF New Associate Form",
        accessor: "pdf_new_associated_person_flag",
        columnType: "boolean",
      },
      { Header: "PDF PST Forms", accessor: "pdf_pst_count" },
      { Header: "PDF OBA Forms", accessor: "pdf_oba_count" },
      { Header: "PDF Brokerage Accts", accessor: "pdf_brokerage_count" },
      {
        Header: "FCS New Associate Form",
        accessor: "submit_new_associated_person_flag",
        isCalculated: true,
        columnType: "boolean",
      },
      {
        Header: "FCS Pre-Registration Form",
        accessor: "submit_prs_flag",
        isCalculated: true,
        columnType: "boolean",
      },
      {
        Header: "FCS PST Forms",
        accessor: "submit_pst_count",
        isCalculated: true,
        columnType: "number",
      },
      {
        Header: "FCS Brokerage Accts",
        accessor: "submit_brokerage_count",
        isCalculated: true,
        columnType: "number",
      },
      {
        Header: "FCS OBA Forms",
        accessor: "submit_oba_count",
        isCalculated: true,
        columnType: "number",
      },
      {
        Header: "Last Login Time",
        accessor: "last_login_time",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "true" },
      },
      {
        Header: "Last Resource Download",
        accessor: "last_resource_download_time",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "true" },
      },
      {
        Header: "Last Portal Access",
        accessor: "last_page_visit_time",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "true" },
      },
      {
        Header: "Last Submission Activity",
        accessor: "submit_last_activity_date",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "false" },
      },
      {
        Header: "Portal Access",
        accessor: "portal_access_flag",
        isCalculated: true,
        columnType: "boolean",
      },
      {
        Header: "New Associate Form",
        accessor: "combined_new_associated_person_flag",
        isCalculated: true,
        columnType: "boolean",
      },
      {
        Header: "Pre-Registration Form",
        accessor: "combined_prs_flag",
        isCalculated: true,
        columnType: "boolean",
      },
      {
        Header: "Total PST Forms",
        accessor: "combined_pst_count",
        isCalculated: true,
        columnType: "number",
      },
      {
        Header: "Total Brokerage Accts",
        accessor: "combined_brokerage_count",
        isCalculated: true,
        columnType: "number",
      },
      {
        Header: "Total OBA Forms",
        accessor: "combined_oba_count",
        isCalculated: true,
        columnType: "number",
      },
    ],
    []
  );

  // Table data
  const data = React.useMemo(() => userAttributes, [userAttributes]);

  // Default values (for new rows)
  const defaultValues = {
    pdf_prs_flag: 0, //! This column and pdf_new_associated_person_flag both seem like boolean values?
    pdf_new_associated_person_flag: 0,
    pdf_pst_count: 0,
    pdf_brokerage_count: 0,
    pdf_oba_count: 0,
  };

  if (loading || viewsLoading) {
    return <RingLoader />;
  } else if (isOnAdminPage()) {
    return (
      <TableV2
        userInfo={userInfo}
        data={data}
        columns={columns}
        addRowFunction={addUser}
        deleteRowFunction={deleteUser}
        updateRowFunction={updateUser}
        doRefresh={doRefresh}
        setDoRefresh={setDoRefresh}
        setDoViewRefresh={setDoViewRefresh}
        views={views}
        defaultView="default"
        defaultValues={defaultValues}
        tableId={TABLE_ID}
      />
    );
  } else {
    return <p>Only available on Admin page.</p>;
  }
};

export default UserDataTable;
