import React from "react";

import { BsArrowDown, BsArrowUp } from "react-icons/bs";

import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useAsyncDebounce,
  setGlobalFilter,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

const DefaultColumnFilter = ({
  column: { rows, preFilteredRows, filterValue, setFilter, id, Header },
}) => {
  return (
    <input
      class="input is-small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Filter on '${Header}'`}
    />
  );
};

export const filterDateString = (rows, id, filterValues) => {
  return rows.filter((row) => {
    const cellDate = row.values[id];
    const cellFormatted =
      cellDate.toLocaleDateString() + " " + cellDate.toLocaleTimeString();
    return cellFormatted.includes(filterValues);
  });
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <input
      key="globalFilter"
      value={globalFilter || ""}
      onChange={(e) => {
        setGlobalFilter(e.target.value);
      }}
      placeholder={`Search all records`}
      class="input is-small"
    />
  );
};

export const filterIsAdmin = (rows, id, filterValues) => {
  return rows.filter((row) => {
    let adminString;
    const adminFlag = row.values[id];
    const adminViewOnlyFlag = row.original.admin_view_only_flag;
    if (!adminFlag) {
      adminString = "false";
    } else if (adminFlag && adminViewOnlyFlag) {
      adminString = "view-only";
    } else {
      adminString = "true";
    }
    return adminString.includes(filterValues);
  });
};

const Table = ({
  columns,
  data,
  sortBy,
  searchText,
  tableName,
  deleteFunc,
  deleteId,
}) => {
  /*
  deleteFunc: function to delete a row. Will pass in deleteId from the row into the delete func
  deleteId: column ID to pass in as the ID for the delete function
  */
  const defaultColumn = React.useMemo(() => ({
    Filter: DefaultColumnFilter,
    globalFilter: GlobalFilter,
  }));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    setGlobalFilter,
    state,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    selectedFlatRows,
    toggleRowSelected,
    toggleAllRowsSelected,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sortBy,
        pageIndex: 0,
      },
      defaultColumn,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [deletedRows, setDeletedRows] = React.useState(0);

  React.useEffect(() => {
    if (
      selectedFlatRows.length > 0 &&
      deletedRows === selectedFlatRows.length
    ) {
      window.alert(
        `Successfully deleted ${deletedRows} rows. Click OK to refresh the page.`
      );
      setDeletedRows(0);
      window.location.reload();
    }
  }, [deletedRows]);

  return (
    <>
      <div className="tableGlobalFilter">
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div className="tableHeaderGroup">
        {headerGroups[0].headers.map((header, i) => {
          return (
            <div key={i}>
              <div className={`tableHeaderDesktop flex`}>
                {header.render("Header")}{" "}
                <span className="flex" {...header.getSortByToggleProps()}>
                  {/* Add a sort direction indicator */}
                  {header.isSorted ? (
                    <div className="tableSortArrow">
                      {header.isSortedDesc ? <BsArrowDown /> : <BsArrowUp />}
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div>{header.render("Filter")}</div>
            </div>
          );
        })}
      </div>
      <div>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <div
              className="tableContainer"
              style={{
                backgroundColor: row.isSelected ? "rgba(255, 0, 0, .5)" : "",
              }}
              onClick={(event) => {
                toggleRowSelected(row.id);
              }}
            >
              {row.cells.map((cell, i) => {
                return (
                  <>
                    <div className={`header header-${i + 1}`}>
                      {cell.column.render("Header")}
                    </div>

                    <div
                      {...cell.row.getRowProps()}
                      className={`entry entry-${i + 1}`}
                    >
                      <div {...cell.getCellProps()}>{cell?.render("Cell")}</div>
                    </div>
                  </>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* Selected rows controller */}
      {selectedFlatRows.length > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            backgroundColor: "#ffffff",
            height: "75px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 10001,
          }}
        >
          <div class="field is-grouped">
            <div class="control">
              <button
                class="button is-small fpDeleteButton"
                onClick={(event) => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete ${selectedFlatRows.length} rows?`
                    )
                  ) {
                    selectedFlatRows.map((row) => {
                      // console.log("Deleting row: ", row.original[deleteId]);
                      deleteFunc(row.original[deleteId]).then((response) => {
                        // console.log("Row deleted", response);
                        setDeletedRows((prev) => prev + 1);
                      });
                    });

                    // alert(
                    //   `Delete ${deletedRows} rows. Click OK to refreh the page.`
                    // );
                    // setDeletedRows(0);
                    // window.location.reload();

                    // if (deletedRows === selectedFlatRows.length) {
                    // }
                  }
                }}
              >
                Delete ({selectedFlatRows.length})
              </button>
            </div>
            <div class="control">
              <button
                class="button is-small"
                onClick={() => toggleAllRowsSelected(false)}
              >
                Unselect All
              </button>
            </div>
          </div>
          <p class="is-italic is-size-7">
            editing table: <strong>{tableName}</strong>
          </p>
        </div>
      )}

      {/* Pagination -- only if you have at least 1 page */}
      {!(!canPreviousPage && !canNextPage) && (
        <div class="columns is-mobile" style={{ marginTop: "10px" }}>
          <div class="column is-one-third">
            <div class="buttons is-left">
              <button
                class="button is-small"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<< First"}
              </button>{" "}
              <button
                class="button is-small"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"< Previous"}
              </button>{" "}
            </div>
          </div>
          <div class="column is-one-third">
            <p class="has-text-centered">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </p>
          </div>
          <div class="column is-one-third">
            <div class="buttons is-right">
              <button
                class="button is-small"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {"Next >"}
              </button>{" "}
              <button
                class="button is-small"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {"Last >>"}
              </button>{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
