import React from "react";
import { navigate } from "gatsby";

// External
import { RingLoader } from "react-spinners";
// Internal
import { supabase } from "../../helpers/supabase";
import TableV2 from "../TableV2";
import {
  isOnAdminPage,
  getTagBgColor,
  getTagFontColor,
} from "../../helpers/globalHelpers";
import { fetchViews } from "../TableV2/helpers";
import {
  formatDate,
  getFirstOfEveryMonthSince,
} from "../../utilities/utilities";

const FORM_ID = "outsideBrokerageAccount";
const FORM_TYPE = "complianceForms";

// data = array of objects; each object represents an edited row from OutsideBrokerageAccountTable
const updateRow = (data) => {
  const metadataBySubmission = {};

  data.forEach((editedRow) => {
    // ...metadata = all key-value pairs of metadata for the row (YYYY-MM-DD: true/false)
    const { submission_id, form_id, row_id, ...metadata } = editedRow;

    // If there's not an object for storing metadata for a given submission, create one
    if (!metadataBySubmission[submission_id]) {
      metadataBySubmission[submission_id] = {};
    }
    // Add a key = row_id, value = all metadata for that row to the metadataBySubmission object
    metadataBySubmission[submission_id][row_id] = metadata;
  });

  const transformed = Object.keys(metadataBySubmission).map((submission_id) => {
    return {
      submission_id: submission_id,
      form_id: FORM_ID,
      metadata: JSON.stringify(metadataBySubmission[submission_id]),
    };
  });

  return supabase
    .from("submission_metadata")
    .upsert(transformed, { onConflict: "submission_id" });
};

function getData() {
  // Fetches data from the database
  return supabase
    .rpc("submissions_with_metadata")
    .select("*")
    .eq("form_id", FORM_ID);
}

// data = array of objects of every Outside Brokerage Account form submission
function processData(data) {
  // This is the return value, which will be an array of objects (the objects being fully processed rows of TableInput data)
  const processedData = [];

  const statusMap = {
    approved: "Active",
    closed: "Closed",
    terminated: "Terminated",
    rejected: "Rejected",
    trails: "Trails",
    hold: "Hold",
    "in progress": "Pending",
    submitted: "Submitted",
    "changes requested": "Changes requested",
    modified: "Modified",
  };

  // Iterate through each user submission (an object of n rows from TableInput)
  data.forEach((submission) => {
    // First add to userSubmission the data that is common to all rows of TableInput data
    const { submission_id, email, status, last_updated_time, form_id } =
      submission;

    const parsedSubmissionMetadata = JSON.parse(submission.metadata);
    // Because TableInput responses are a JSON stringified array, we need to parse it twice
    const jsonFormResponses = JSON.parse(submission.response);
    const parsedTableInputRows = JSON.parse(jsonFormResponses[0]);

    // Each row is a row of TableInput data
    parsedTableInputRows.forEach((row) => {
      // processedRow will contain its common submission data, the submitted data from TableInput, and the metadata for that row
      const processedRow = {
        // key = accessor, value = value to display,
        row_id: row.rowId,
        accountOwner: row.value["accountOwner"],
        accountNumber: row.value["accountNumber"],
        type: row.value["type"],
        firm: row.value["firm"],
        comments: row.value["comments"],
      };
      if (parsedSubmissionMetadata) {
        // The actual metadata for a row; and object of key-value pairs indicating whether an admin has received an account statement for a given month
        const rowMetadata = parsedSubmissionMetadata[row.rowId];
        for (const month in rowMetadata) {
          // key = 'YYYY-MM-DD', value = true/false
          processedRow[month] = rowMetadata[month];
        }
      }
      // Combine common submission data, row-specific reponse data, and row-specific metadata into one object and add to processedData array
      processedData.push({
        submission_id,
        email,
        status: status in statusMap ? statusMap[status] : status,
        last_updated_time,
        form_id,
        ...processedRow,
      });
    });
  });
  return processedData;
}

const OutsideBrokerageAccountTable = ({ userInfo }) => {
  const userEmail = userInfo.email;

  const TABLE_ID = "outsideBrokerageAccount";

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  // Dynamically adds a column to display metadata for every 1st of the month starting from whatever date is passed in
  const metadataColumnsToRender = getFirstOfEveryMonthSince("2022-01-01").map(
    (yearMonth) => {
      return {
        Header: formatDate(yearMonth),
        accessor: yearMonth,
        columnType: "boolean",
      };
    }
  );

  React.useEffect(() => {
    getData().then((response) => {
      // console.log(processData(response.data));
      const processedData = processData(response.data);
      setData(processedData);
      setLoading(false);
    });
  }, []);

  const [views, setViews] = React.useState({});
  const [viewsLoading, setViewsLoading] = React.useState(true);
  const [doViewRefresh, setDoViewRefresh] = React.useState(true);
  React.useEffect(() => {
    fetchViews(
      userEmail,
      TABLE_ID,
      setViews,
      setViewsLoading,
      setDoViewRefresh
    );
  }, [doViewRefresh]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Submission ID",
        accessor: "submission_id",
        keepInUpdate: true,
        isCalculated: true,
      },
      {
        Header: "Row ID",
        accessor: "row_id",
        idColumn: true,
        isCalculated: true,
      },
      {
        Header: "Rep Email",
        accessor: "email",
        isCalculated: true,
      },
      { Header: "Account Owner", accessor: "accountOwner", isCalculated: true },
      { Header: "Account #", accessor: "accountNumber", isCalculated: true },
      { Header: "Type", accessor: "type", isCalculated: true },
      { Header: "Brokerage Firm", accessor: "firm", isCalculated: true },
      { Header: "Comments", accessor: "comments", isCalculated: true },
      {
        Header: "Last Updated",
        accessor: "last_updated_time",
        isCalculated: true,
        columnType: "date",
        options: { displayDateTime: "true" },
      },
      {
        Header: "Status",
        accessor: "status",
        columnType: "enum",
        isCalculated: true,
        options: {
          values: [
            "Pending", // "in progress"
            "Submitted",
            "Changes requested",
            "Modified",
            "Active", // "approved"
            "Rejected",
            "Closed",
            "Terminated",
            "Trails",
            "Hold",
          ],
          className: "tag",
          bgColorMap: {
            Pending: getTagBgColor("Pending"),
            Submitted: getTagBgColor("Submitted"),
            "Changes requested": getTagBgColor("Changes requested"),
            Modified: getTagBgColor("Modified"),
            Active: getTagBgColor("Active"),
            Rejected: getTagBgColor("Rejected"),
            Closed: getTagBgColor("Closed"),
            Terminated: getTagBgColor("Terminated"),
            Trails: getTagBgColor("Trails"),
            Hold: getTagBgColor("Hold"),
          },
          fontColorMap: {
            Pending: getTagFontColor("Pending"),
            Submitted: getTagFontColor("Submitted"),
            "Changes requested": getTagFontColor("Changes requested"),
            Modified: getTagFontColor("Modified"),
            Active: getTagFontColor("Active"),
            Rejected: getTagFontColor("Rejected"),
            Closed: getTagFontColor("Closed"),
            Terminated: getTagFontColor("Terminated"),
            Trails: getTagFontColor("Trails"),
            Hold: getTagFontColor("Hold"),
          },
        },
      },
      ...metadataColumnsToRender,
      {
        Header: "Form",
        accessor: "edit",
        Cell: ({ cell }) => {
          // const [formType, formId, submissionId, status] = value.split("|");
          const submissionId = cell.row.original.submission_id;
          return (
            <button
              class="button is-small"
              onClick={(event) => {
                // Prevent default form submission
                event.preventDefault();
                navigate(
                  `/editForm?formType=${FORM_TYPE}&formId=${FORM_ID}&submissionId=${submissionId}&prevPage=${window.location.pathname}`
                );
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  const dataForTable = React.useMemo(() => data, [data]);

  if (loading || viewsLoading) {
    return <RingLoader />;
  } else if (isOnAdminPage()) {
    return (
      <TableV2
        userInfo={userInfo}
        data={dataForTable}
        columns={columns}
        views={views}
        defaultView="default"
        tableId={TABLE_ID}
        updateRowFunction={updateRow}
        disableAdd={true}
        disableDelete={true}
        setDoViewRefresh={setDoViewRefresh}
      />
    );
  }
};

export default OutsideBrokerageAccountTable;
