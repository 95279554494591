import React from "react";

import { useForm } from "react-hook-form";
import { FaRegTrashAlt } from "react-icons/fa";
import { RingLoader } from "react-spinners";

import { sleep, supabase } from "../helpers/supabase";
import { useAdmin } from "../context/adminContext";
import { logDev } from "../helpers/globalHelpers";
import { loginFieldStyles } from "../styles/styles";

import { useAuth } from "../context/auth";

import Table, { filterIsAdmin } from "./Table";

const Users = () => {
  const { adminViewOnlyFlag } = useAuth();
  const {
    users,
    getUsersFromSupabase,
    setUsersLoading,
    setUsers,
    usersLoading,
  } = useAdmin();

  const { register, handleSubmit } = useForm();

  const addUser = async (data) => {
    setUsersLoading(true);
    const { error } = await supabase.from("dim_email_dba").insert([
      {
        email: data.email,
        dba: data.dba,
        user_type: data.user_type,
      },
    ]);

    if (error) {
      if (error) {
        logDev(error);
        alert(error.message);
      }
    }

    // If admin, add to admins table (required for RLS)
    if (data.admin === "true" || data.admin === "view-only") {
      const viewOnlyFlag = data.admin === "view-only";
      const { error: errorAdminInsert } = await supabase
        .from("admins")
        .insert([{ email: data.email, view_only_flag: viewOnlyFlag }]);
      if (errorAdminInsert) {
        logDev(errorAdminInsert);
        alert(errorAdminInsert.message);
      }
    }

    setUsers(undefined);
    document.querySelectorAll(".input").forEach((elem) => (elem.value = ""));
    await sleep(50);
    getUsersFromSupabase();
  };

  const deleteUser = async (email) => {
    if (window.confirm(`Are you sure you want to delete user "${email}"?`)) {
      setUsersLoading(true);
      await supabase.from("dim_email_dba").delete().eq("email", email);
      await supabase.from("admins").delete().eq("email", email);

      setUsers(undefined);
      await sleep(50);

      getUsersFromSupabase();
    } else {
      return;
    }
  };

  const deleteUserForSelectedDelete = (email) => {
    return supabase.from("dim_email_dba").delete().eq("email", email);
  };

  const columns = React.useMemo(() => [
    {
      Header: "Email",
      accessor: "email",
    },
    { Header: "DBA", accessor: "dba" },
    {
      Header: "Is Admin?",
      accessor: "admin_flag",
      Cell: ({ row }) => {
        if (!row.original.admin_flag) {
          return <p>false</p>;
        } else if (
          row.original.admin_flag &&
          row.original.admin_view_only_flag
        ) {
          return <p>view-only</p>;
        } else {
          return <p>true</p>;
        }
      },
      filter: filterIsAdmin,
    },
    { Header: "Type", accessor: "user_type" },
    {
      Header: "",
      accessor: (row) => row.email,
      id: "delete",
      Cell: ({ cell: { value } }) => (
        <button
          class="button is-small"
          onClick={() => {
            deleteUser(value);
          }}
          disabled={adminViewOnlyFlag}
        >
          <FaRegTrashAlt />
        </button>
      ),
      Filter: <div />,
    },
  ]);

  const data = React.useMemo(() => users, [users]);

  if (usersLoading) {
    return (
      <div class="has-text-centered">
        <RingLoader />
      </div>
    );
  } else {
    return (
      <>
        <Table
          columns={columns}
          data={data}
          sortBy={[{ id: "dba", desc: false }]}
          searchText="Search users by email, dba, type, etc."
          tableName="Access Management"
          deleteFunc={deleteUserForSelectedDelete}
          deleteId="email"
        />
        <form class="box is-fullwidth" onSubmit={handleSubmit(addUser)}>
          <div class="field" style={loginFieldStyles}>
            <div class="label">Email</div>
            <input
              class="input"
              type="email"
              {...register("email", { required: true })}
            />
          </div>
          <div class="field" style={loginFieldStyles}>
            <div class="label">DBA</div>
            <input
              class="input"
              type="text"
              {...register("dba", { required: true })}
            />
          </div>
          <div class="field" style={loginFieldStyles}>
            <div class="label">Is Admin?</div>
            <div class="select is-fullwidth">
              <select {...register("admin", { required: true })}>
                <option value={false}>false</option>
                <option value={true}>true</option>
                <option value={"view-only"}>view-only</option>
              </select>
            </div>
          </div>
          <div class="field" style={loginFieldStyles}>
            <div class="label">User Type</div>
            <div class="select is-fullwidth">
              <select {...register("user_type", { required: true })}>
                <option value="regular">regular</option>
                <option value={"m&a"}>{"m&a"}</option>
              </select>
            </div>
          </div>
          <button
            class="button is-fullwidth fpLoginButton"
            style={adminViewOnlyFlag ? { color: "black" } : {}}
            type="submit"
            disabled={adminViewOnlyFlag}
          >
            Add User
          </button>
        </form>
      </>
    );
  }
};

export default Users;
